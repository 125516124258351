import React from "react"
import Layout from "../components/layout"

import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"

const PipasPistaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(pipas_pista)/" } }
        sort: { order: ASC, fields: [frontmatter___id] }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              id
            }
            fields {
              slug
            }
            html
            excerpt(pruneLength: 280)
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <h4 className="text-center pt-6">Süli István</h4>
            <h1 className="text-center pt-3">
              A vizsgálóbíró esete a kocsissal,
            </h1>
            <h4 className="text-center pt-3">avagy Pipás Pista gyilkosságai</h4>
            <h4 className="text-center pt-3">Tabló</h4>
            <h5 className="text-center">(Kézirat)</h5>
            <hr />
            <div className="container">
              {data.allMarkdownRemark.edges.map(edge => {
                return (
                  <div className="post" key={edge.node.id}>
                    {/* <Link to={edge.node.fields.slug}>
                      <h5 className="pt-1">{edge.node.frontmatter.title}</h5>
                    </Link> */}

                    {/* <h5 className="pt-1">{edge.node.frontmatter.title}</h5> */}
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: edge.node.html,
                      }}
                    ></span>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </Layout>
  )
}

export default PipasPistaPage
